import produce from "immer";
import { isArray, set } from "lodash";
import get from "lodash/get";
import { Reducer } from "redux";

import { getOrCreatePropAtPath, mergeOrgMember } from "../../utils/general";
import { logout } from "../auth/routines";
import { resendMemberInvitation } from "./";
import {
  addMemberToOrganisationServiceArea,
  addOrganisationServiceArea,
  createMember,
  deleteMember,
  fetchAllHeadOfficeOrganisations,
  fetchAllOrganisations,
  fetchInvitedMembers,
  fetchMemberRoles,
  fetchOrganisationById,
  fetchOrganisationByMember,
  fetchOrganisationByName,
  fetchPendingOrganisationSubscription,
  fetchSubOrganisationById,
  inviteMember,
  openInvite,
  patchFbSocialAsset,
  patchOrganisation,
  patchOrganisationState,
  patchSubOrgFbSocialAsset,
  postAuthOrgInit,
  removeMemberFromOrganisationServiceArea,
  removeNotificationRecipients,
  removeOrganisationServiceArea,
  selectOrganisation,
  selectSubOrganisation,
  setColors,
  setOrganisationSettings,
  setLocaleSettings,
  setNotifications,
  setSocialAssetConfig,
  setSyndicatorSettings,
  toggleFullAddress,
  updateDefaultNotificationRecipient,
  updateMember,
  updateUserMember,
  updateMemberProfileImage,
  updateNotificationRecipients,
  updateOrganisation,
  uploadCoverImage,
  uploadLogo,
  updateShowPartners,
  patchSmartFeedSettings,
  updateShowReferralPartners,
  setEngagementMaximiser,
  triggerSmartFeedSyndicator,
  moveFbPageToOrg,
  removeUserFbPage,
  setPaymentMethods,
  setCampaignSettings,
} from "./routines";
import { FbSocialAsset, Organisation, OrganisationState } from "./types";

export const initialState: OrganisationState = {
  errors: undefined,
  loading: false,
  logoLoading: false,
  fullAddressLoading: false,
  partnersLoading: false,
  referralPartnersLoading: false,
  coverImageLoading: false,
  memberProfileImageLoading: false,
  memberLoading: false,
  openInviteForm: { open: false, email: undefined },
  organisation: undefined,
  subOrganisation: undefined,
  organisations: [],
  headOfficeOrganisations: [],
  roles: [],
  members: [],
  memberOrganisations: [],
  initializing: false,
  showPublicTemplatesLoading: false,
  organisationSearchResults: [],
};

const reducer: Reducer<OrganisationState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    case setEngagementMaximiser.TRIGGER:
    case updateOrganisation.TRIGGER:
    case fetchOrganisationByMember.TRIGGER:
    case fetchMemberRoles.TRIGGER:
    case fetchAllOrganisations.TRIGGER:
    case fetchAllHeadOfficeOrganisations.TRIGGER:
    case openInvite.TRIGGER:
    case fetchOrganisationByName.TRIGGER:
    case deleteMember.TRIGGER:
    case setNotifications.TRIGGER:
    case setColors.TRIGGER:
    case setPaymentMethods.TRIGGER:
    case setOrganisationSettings.TRIGGER:
    case setLocaleSettings.TRIGGER:
    case setSyndicatorSettings.TRIGGER:
    case selectOrganisation.TRIGGER:
    case selectSubOrganisation.TRIGGER:
    case fetchOrganisationById.TRIGGER:
    case removeOrganisationServiceArea.TRIGGER:
    case addOrganisationServiceArea.TRIGGER:
    case removeMemberFromOrganisationServiceArea.TRIGGER:
    case addMemberToOrganisationServiceArea.TRIGGER:
    case updateNotificationRecipients.TRIGGER:
    case setSocialAssetConfig.TRIGGER:
    case updateDefaultNotificationRecipient.TRIGGER:
    case fetchSubOrganisationById.TRIGGER:
    case removeNotificationRecipients.TRIGGER:
    case resendMemberInvitation.TRIGGER:
    case patchSmartFeedSettings.TRIGGER:
    case triggerSmartFeedSyndicator.TRIGGER:
    case fetchInvitedMembers.TRIGGER:
    case moveFbPageToOrg.TRIGGER:
    case removeUserFbPage.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    case updateMemberProfileImage.TRIGGER: {
      return { ...state, memberProfileImageLoading: true, errors: undefined };
    }

    case uploadLogo.TRIGGER: {
      return { ...state, logoLoading: true, errors: undefined };
    }

    case uploadCoverImage.TRIGGER: {
      return { ...state, coverImageLoading: true, errors: undefined };
    }

    case toggleFullAddress.TRIGGER: {
      return { ...state, fullAddressLoading: true, errors: undefined };
    }

    case updateShowPartners.TRIGGER: {
      return { ...state, partnersLoading: true, errors: undefined };
    }
    case updateShowReferralPartners.TRIGGER:
      return { ...state, referralPartnersLoading: true, errors: undefined };
    case setCampaignSettings.TRIGGER: {
      return { ...state, showPublicTemplatesLoading: true, errors: undefined };
    }
    case updateMember.TRIGGER:
    case updateUserMember.TRIGGER:
    case createMember.TRIGGER:
    case inviteMember.TRIGGER: {
      return { ...state, memberLoading: true, errors: undefined };
    }

    case postAuthOrgInit.TRIGGER: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.initializing = true;
        }
      );

      return newDerivedState;
    }

    // Success

    case deleteMember.SUCCESS:
    case updateMember.SUCCESS:
    case createMember.SUCCESS:
    case inviteMember.SUCCESS:
    case uploadLogo.SUCCESS:
    case uploadCoverImage.SUCCESS:
    case updateOrganisation.SUCCESS:
    case setNotifications.SUCCESS:
    case setColors.SUCCESS:
    case setPaymentMethods.SUCCESS:
    case setOrganisationSettings.SUCCESS:
    case setLocaleSettings.SUCCESS:
    case setSyndicatorSettings.SUCCESS:
    case removeOrganisationServiceArea.SUCCESS:
    case addOrganisationServiceArea.SUCCESS:
    case removeMemberFromOrganisationServiceArea.SUCCESS:
    case addMemberToOrganisationServiceArea.SUCCESS:
    case updateNotificationRecipients.SUCCESS:
    case updateDefaultNotificationRecipient.SUCCESS:
    case removeNotificationRecipients.SUCCESS:
    case setSocialAssetConfig.SUCCESS:
    case toggleFullAddress.SUCCESS:
    case updateShowPartners.SUCCESS:
    case updateShowReferralPartners.SUCCESS:
    case setEngagementMaximiser.SUCCESS:
    case setCampaignSettings.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          const updatedOrg = action.payload as Organisation;

          if (
            draft.subOrganisation &&
            draft.subOrganisation._id === updatedOrg._id
          ) {
            draft.subOrganisation = updatedOrg;
          } else {
            draft.organisation = updatedOrg;
            draft.members = updatedOrg.members || [];
          }
        }
      );

      return newDerivedState;
    }

    case updateUserMember.SUCCESS: {
      //set(state, "organisation.members", action.payload);
      const members = get(state, "organisation.members", []);
      for (let i = 0; i < members.length; i++) {
        if (
          members[i].user &&
          action.payload &&
          members[i].user._id === action.payload._id
        ) {
          members[i].user = action.payload;
        }
      }

      set(state, "organisation.members", [...members]);

      return {
        ...state,
      };
    }

    case selectOrganisation.SUCCESS:
    case patchSmartFeedSettings.SUCCESS:
    case fetchOrganisationById.SUCCESS: {
      return {
        ...state,
        organisation: action.payload,
        members: get(action, "payload.members", []),
      };
    }

    case fetchOrganisationByMember.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          const organisations = isArray(action.payload) ? action.payload : [];

          draft.memberOrganisations = organisations;
          draft.organisation = organisations.length
            ? organisations[0]
            : undefined;
          draft.members = get(organisations, "[0].members", []);
        }
      );

      return newDerivedState;
    }

    case resendMemberInvitation.SUCCESS: {
      return {
        ...state,
        organisation: get(action, "payload.organisation", undefined),
        members: get(action, "payload.organisation.members", []),
      };
    }

    case selectSubOrganisation.SUCCESS: {
      return {
        ...state,
        subOrganisation: action.payload,
        members: get(action, "payload.members", []),
      };
    }

    case fetchSubOrganisationById.SUCCESS: {
      return {
        ...state,
        subOrganisation: action.payload,
        members: get(action, "payload.members", []),
      };
    }

    case updateMemberProfileImage.SUCCESS: {
      return {
        ...state,
        organisation: mergeOrgMember(state.organisation!, action.payload),
      };
    }

    case fetchMemberRoles.SUCCESS: {
      return { ...state, roles: action.payload };
    }

    case fetchAllOrganisations.SUCCESS: {
      return {
        ...state,
        organisations: action.payload.organisations,
      };
    }
    case fetchAllHeadOfficeOrganisations.SUCCESS: {
      return {
        ...state,
        headOfficeOrganisations: action.payload,
      };
    }

    case openInvite.SUCCESS: {
      return {
        ...state,
        openInviteForm: action.payload,
      };
    }

    case fetchInvitedMembers.SUCCESS:
    case removeUserFbPage.SUCCESS: {
      return {
        ...state,
        members: action.payload,
        organisation: { ...state.organisation, members: action.payload },
      };
    }

    case patchOrganisation.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.organisation = {
            ...draft.organisation,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case fetchPendingOrganisationSubscription.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.pendingSubscription = get(action, "payload[0]", null);
        }
      );

      return newDerivedState;
    }

    case patchOrganisationState.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          Object.assign(draft, action.payload);
        }
      );

      return newDerivedState;
    }

    case patchFbSocialAsset.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          const fbSocialAsset: FbSocialAsset = getOrCreatePropAtPath(
            draft,
            "organisation.settings.socialAssetConfiguration.facebook"
          );
          Object.assign(fbSocialAsset, action.payload);
        }
      );

      return newDerivedState;
    }

    case patchSubOrgFbSocialAsset.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          const fbSocialAsset: FbSocialAsset = getOrCreatePropAtPath(
            draft,
            "subOrganisation.settings.socialAssetConfiguration.facebook"
          );
          Object.assign(fbSocialAsset, action.payload);
        }
      );

      return newDerivedState;
    }

    case postAuthOrgInit.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.organisation = action.payload.organisation;
          draft.subOrganisation = action.payload.subOrganisation;
        }
      );

      return newDerivedState;
    }
    case triggerSmartFeedSyndicator.SUCCESS:
      return { ...state, errors: undefined };

    case moveFbPageToOrg.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          if (draft.subOrganisation && draft.subOrganisation._id) {
            //update sub-org facebook pages
            const fbSocialAsset: FbSocialAsset = getOrCreatePropAtPath(
              draft,
              "subOrganisation.settings.socialAssetConfiguration.facebook.facebookpage"
            );
            Object.assign(
              fbSocialAsset,
              get(action, "payload.facebookpage", [])
            );

            //update sub-org members
            const members = getOrCreatePropAtPath(
              draft,
              "subOrganisation.members"
            );
            Object.assign(members, get(action, "payload.members", []));
          } else {
            //update org facebook pages
            const fbSocialAsset: FbSocialAsset = getOrCreatePropAtPath(
              draft,
              "organisation.settings.socialAssetConfiguration.facebook.facebookpage"
            );
            Object.assign(
              fbSocialAsset,
              get(action, "payload.facebookpage", [])
            );

            //update org members
            const orgMembers = getOrCreatePropAtPath(
              draft,
              "organisation.members"
            );
            Object.assign(orgMembers, get(action, "payload.members", []));

            //update member organisation
            const memberOrgToUpdate = draft.memberOrganisations.findIndex(
              (org) => org._id === get(draft, "organisation._id")
            );
            if (memberOrgToUpdate !== -1) {
              const organisation: Organisation =
                draft.memberOrganisations[memberOrgToUpdate];

              const fbPagesSocial: FbSocialAsset = getOrCreatePropAtPath(
                organisation,
                "settings.socialAssetConfiguration.facebook.facebookpage"
              );
              Object.assign(
                fbPagesSocial,
                get(action, "payload.facebookpage", [])
              );

              const memberOrgMembers = getOrCreatePropAtPath(
                organisation,
                "members"
              );
              Object.assign(
                memberOrgMembers,
                get(action, "payload.members", [])
              );

              draft.memberOrganisations[memberOrgToUpdate] = organisation;
            }

            //update members
            const members = getOrCreatePropAtPath(draft, "members");
            Object.assign(members, get(action, "payload.members", []));
          }
        }
      );

      return newDerivedState;
    }

    case fetchOrganisationByName.SUCCESS: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.organisationSearchResults = action.payload;
        }
      );

      return newDerivedState;
    }

    // Failure

    case setEngagementMaximiser.FAILURE:
    case fetchOrganisationByName.FAILURE:
    case updateMember.FAILURE:
    case updateUserMember.FAILURE:
    case fetchOrganisationByMember.FAILURE:
    case deleteMember.FAILURE:
    case createMember.FAILURE:
    case inviteMember.FAILURE:
    case openInvite.FAILURE:
    case fetchOrganisationById.FAILURE:
    case setNotifications.FAILURE:
    case setColors.FAILURE:
    case setPaymentMethods.FAILURE:
    case setOrganisationSettings.FAILURE:
    case setLocaleSettings.FAILURE:
    case setSyndicatorSettings.FAILURE:
    case updateOrganisation.FAILURE:
    case selectOrganisation.FAILURE:
    case selectSubOrganisation.FAILURE:
    case fetchMemberRoles.FAILURE:
    case removeOrganisationServiceArea.FAILURE:
    case addOrganisationServiceArea.FAILURE:
    case removeMemberFromOrganisationServiceArea.FAILURE:
    case addMemberToOrganisationServiceArea.FAILURE:
    case updateNotificationRecipients.FAILURE:
    case updateShowPartners.FAILURE:
    case updateShowReferralPartners.FAILURE:
    case updateDefaultNotificationRecipient.FAILURE:
    case removeNotificationRecipients.FAILURE:
    case fetchAllHeadOfficeOrganisations.FAILURE:
    case setSocialAssetConfig.FAILURE:
    case updateMemberProfileImage.FAILURE:
    case fetchSubOrganisationById.FAILURE:
    case fetchAllOrganisations.FAILURE:
    case resendMemberInvitation.FAILURE:
    case toggleFullAddress.FAILURE:
    case patchSmartFeedSettings.FAILURE:
    case triggerSmartFeedSyndicator.FAILURE:
    case fetchInvitedMembers.FAILURE:
    case moveFbPageToOrg.FAILURE:
    case removeUserFbPage.FAILURE:
    case setCampaignSettings.FAILURE: {
      return { ...state, errors: action.payload };
    }

    // Fulfill
    case setEngagementMaximiser.FULFILL:
    case fetchOrganisationByName.FULFILL:
    case updateMember.FULFILL:
    case deleteMember.FULFILL:
    case createMember.FULFILL:
    case inviteMember.FULFILL:
    case fetchOrganisationByMember.FULFILL:
    case updateOrganisation.FULFILL:
    case setNotifications.FULFILL:
    case setColors.FULFILL:
    case setPaymentMethods.FULFILL:
    case setOrganisationSettings.FULFILL:
    case setLocaleSettings.FULFILL:
    case removeOrganisationServiceArea.FULFILL:
    case addOrganisationServiceArea.FULFILL:
    case removeMemberFromOrganisationServiceArea.FULFILL:
    case addMemberToOrganisationServiceArea.FULFILL:
    case updateNotificationRecipients.FULFILL:
    case updateDefaultNotificationRecipient.FULFILL:
    case removeNotificationRecipients.FULFILL:
    case setSyndicatorSettings.FULFILL:
    case selectOrganisation.FULFILL:
    case selectSubOrganisation.FULFILL:
    case fetchAllOrganisations.FULFILL:
    case fetchAllHeadOfficeOrganisations.FULFILL:
    case fetchOrganisationById.FULFILL:
    case fetchSubOrganisationById.FULFILL:
    case setSocialAssetConfig.FULFILL:
    case fetchMemberRoles.FULFILL:
    case resendMemberInvitation.FULFILL:
    case patchSmartFeedSettings.FULFILL:
    case triggerSmartFeedSyndicator.FULFILL:
    case fetchInvitedMembers.FULFILL:
    case moveFbPageToOrg.FULFILL:
    case removeUserFbPage.FULFILL: {
      return { ...state, loading: false, memberLoading: false };
    }

    case uploadLogo.FULFILL: {
      return { ...state, logoLoading: false };
    }

    case uploadCoverImage.FULFILL: {
      return { ...state, coverImageLoading: false };
    }

    case updateMemberProfileImage.FULFILL: {
      return { ...state, memberProfileImageLoading: false };
    }
    case toggleFullAddress.FULFILL: {
      return { ...state, fullAddressLoading: false };
    }
    case updateShowPartners.FULFILL: {
      return { ...state, partnersLoading: false };
    }
    case updateShowReferralPartners.FULFILL: {
      return { ...state, referralPartnersLoading: false };
    }
    case setCampaignSettings.FULFILL: {
      return { ...state, showPublicTemplatesLoading: false };
    }
    case logout.SUCCESS:
    case logout.FULFILL: {
      return initialState;
    }

    case postAuthOrgInit.FULFILL: {
      const newDerivedState = produce<OrganisationState>(
        state,
        (draft: OrganisationState) => {
          draft.initializing = false;
        }
      );

      return newDerivedState;
    }

    default: {
      return state;
    }
  }
};
export { reducer as organisationReducer };
