import { Location } from "history";
import get from "lodash/get";
import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route as BasicRoute, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import Route from "./components/router/CustomRoute";
import LoadingPage from "./components/ui/LoadingPage";
import { ApplicationState } from "./store";
import { AuthenticatedUser, authenticatePromise } from "./store/auth";
import { fetchActiveLocales } from "./store/defaults";
import { Organisation } from "./store/organisation";
import { isVerificationRequired } from "./utils/verification";

const AccountPage = lazy(() => import("./pages/account"));
const ChatsPage = lazy(() => import("./pages/chats"));
const CommunicationPage = lazy(() => import("./pages/communication"));
const CampaignsPage = lazy(() => import("./pages/campaigns"));
const ContentPage = lazy(() => import("./pages/content"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const ForgotPasswordPage = lazy(() => import("./pages/forgotPassword"));
const InvitePage = lazy(() => import("./pages/invite"));
const LoginPage = lazy(() => import("./pages/login"));
const NoAccessPage = lazy(() => import("./pages/login/noAccess"));
const EmptyState = lazy(() => import("./pages/emptyOrganisation"));
const RegisterPage = lazy(() => import("./pages/register"));
const ResetPasswordPage = lazy(() => import("./pages/resetPassword"));
const PartnersPage = lazy(() => import("./pages/partners"));
const SubscriptionPlansPage = lazy(() => import("./pages/subscriptionPlans"));
// const TenantsPage = lazy(() => import("./pages/tenants"));
const VerificationPage = lazy(() => import("./pages/verify"));
const CampaignPlansPage = lazy(() => import("./pages/campaign-plans"));
const PropertiesPage = lazy(() => import("./pages/properties"));
const SettingsPage = lazy(() => import("./pages/settings"));

interface PropsFromState {
  loading: boolean;
  location: Location;
  authenticated: boolean;
  user?: AuthenticatedUser;
  verified: boolean;
  organisation?: Organisation;
  isOrgInit: boolean;
}

interface PropsFromDispatch {
  authenticatePromise: typeof authenticatePromise;
  fetchLocales: typeof fetchActiveLocales;
}

type AllProps = PropsFromState & PropsFromDispatch;

interface State {
  requireVerification: boolean;
}

class Routes extends React.Component<AllProps, State> {
  public readonly state: State = {
    requireVerification: false,
  };

  public componentDidMount() {
    const { authenticated } = this.props;

    if (!authenticated) {
      this.props
        .authenticatePromise({ updateLastLogin: true })
        .then(() => {
          this.checkVerification();
        })
        .catch((error) => {
          localStorage.removeItem("auth_token");
        });
    }
    this.props.fetchLocales();
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { authenticated, verified } = this.props;
    if (prevProps.authenticated !== authenticated && !verified) {
      this.checkVerification();
    }
  }

  public setPage = (title: string) => {
    const { organisation } = this.props;
    switch (title) {
      case "properties":
        return organisation ? PropertiesPage : EmptyState;
      case "chats":
        return organisation ? ChatsPage : EmptyState;
      case "content":
        return organisation ? ContentPage : EmptyState;
      case "account":
        return organisation ? AccountPage : EmptyState;
      case "campaigns":
        return organisation ? CampaignsPage : EmptyState;
      case "dashboard":
        return organisation ? DashboardPage : EmptyState;
      case "partners":
        return organisation ? PartnersPage : EmptyState;
      case "subscription-plans":
        return organisation ? SubscriptionPlansPage : EmptyState;
      case "campaign-plans":
        return organisation ? CampaignPlansPage : EmptyState;
      case "settings":
        return organisation ? SettingsPage : EmptyState;
      default:
        return EmptyState;
    }
  };

  public render() {
    const { location, loading, verified, authenticated, isOrgInit } =
      this.props;
    const { requireVerification } = this.state;

    const previousUrl = get(location, "state.from");
    const rootUrl = previousUrl || "/campaigns";

    if (isOrgInit || loading) {
      return <LoadingPage />;
    }

    if (authenticated && !verified && requireVerification) {
      return (
        <Suspense fallback={<LoadingPage />}>
          <Switch location={location}>
            <BasicRoute
              exact={true}
              path="/verify"
              component={VerificationPage}
            />
            <Redirect from="/*" to="/verify" />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<LoadingPage />}>
        <Switch location={location}>
          <Route
            path="/login"
            component={LoginPage}
            redirectToPathWhenAuthenticated={rootUrl}
          />
          <Route
            path="/register"
            component={RegisterPage}
            redirectToPathWhenAuthenticated={rootUrl}
          />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route
            exact={true}
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <Route
            path="/invite"
            component={InvitePage}
            redirectToPathAuthenticated={rootUrl}
          />
          <Route
            path="/dashboard"
            component={this.setPage("dashboard")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/campaigns"
            component={this.setPage("campaigns")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/communication"
            component={CommunicationPage}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/account"
            component={this.setPage("account")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
            title="My Profile"
          />
          <Route
            path="/content"
            component={this.setPage("content")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/content"
            component={this.setPage("content")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/properties"
            component={this.setPage("properties")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/chats"
            component={this.setPage("chats")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/partners"
            component={this.setPage("partners")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/subscription-plans"
            component={this.setPage("subscription-plans")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            exact={true}
            path="/noaccess"
            title="Restricted"
            component={NoAccessPage}
          />
          <Route
            path="/campaign-plans"
            component={this.setPage("campaign-plans")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Route
            path="/settings"
            component={this.setPage("settings")}
            routeRoleGuard={["user"]}
            redirectToPathWhenFail="/login"
          />
          <Redirect from="/" to="/login" />
        </Switch>
      </Suspense>
    );
  }

  private checkVerification() {
    const requireVerification = isVerificationRequired();
    this.setState({ requireVerification });
  }
}

const mapStateToProps = ({
  router,
  auth,
  defaults,
  organisation,
}: ApplicationState) => ({
  authenticated: auth.authenticated,
  loading: auth.authenticateLoading,
  location: router.location,
  locales: defaults.locales,
  user: auth.user,
  verified: !!auth.user && auth.user.status === "Verified",
  organisation: get(
    organisation,
    "subOrganisation",
    get(organisation, "organisation")
  ),
  isOrgInit: organisation.initializing,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators({ authenticatePromise }, dispatch),
  ...bindActionCreators({ fetchLocales: fetchActiveLocales }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes as any);
